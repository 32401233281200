import React from "react"
import TileList from "../../components/tilelist"
import SubnavList from "../../components/subnavList"

const PageContent = () => (
  <div className="contentArea">
    <h2>Quality Web design and development</h2>
    <p>Need to sell things, setup a brand, or promote your work?</p>
    <p>
      Get a site that not only works on all modern devices and browsers, but is
      also fully optimized for search and social.
    </p>
  </div>
)

const websitePage = () => {
  return (
    <div className="projects">
      <SubnavList />
      <TileList />
      <PageContent />
    </div>
  )
}

export default websitePage;
